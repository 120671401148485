@tailwind base;
@tailwind components;
@tailwind utilities;


 /* poppins */

@font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Light.ttf);                
    font-weight: 300;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Regular.ttf);                
    font-weight: 400;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Medium.ttf);                
    font-weight: 500;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-SemiBold.ttf);                
    font-weight: 600;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Bold.ttf);                
    font-weight: 700;
  }
  
  .poppins {
    font-family: poppins;
  }

    /* inter */
  
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-Light.ttf);                
      font-weight: 300;
    }
    
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-Regular.ttf);                
      font-weight: 400;
    }
    
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-Medium.ttf);                
      font-weight: 500;
    }
    
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-SemiBold.ttf);                
      font-weight: 600;
    }
    
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-Bold.ttf);                
      font-weight: 700;
    }
    
    .inter {
      font-family: inter;
    }
    
  
body {
  background-color: #080909;
}

.token-buy-border {
  background: conic-gradient(from -90deg at 50% 50%, #1DF49A 0deg, #F4911D 91.88deg, #C91DF4 178.77deg, #6F1ADB 270deg, #1DF49A 360deg);
}

.static-block-bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
  linear-gradient(180deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.001) 100%);
}

.static-block-border {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.title-text-color {
  background: radial-gradient(493.16% 266.63% at 50% -148.57%, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tokenomics-menu-border {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%);
}

.tokenomics-menu-bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%),
  linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03));
}

.giveaway_block_bg {
  background: linear-gradient(90deg, #161717 0%, #0A0A0A 63.53%, #0F0F0F 100%);
}